import { useCallback, useEffect, useReducer, useState } from 'react';
import {
    RegulatorForEdit,
    SpecificationInterface,
    ToleranceMappedField,
    ValidationInterface,
} from 'queries/specifications/types';
import useUpdateValidation from 'queries/specifications/useUpdateValidation';
import { getRegulatorsForEdit } from 'queries/specifications/utils';

import EditForm from '../EditForm/EditForm';

import { EditTolerancesContext } from './EditTolerancesContext';
import { EditTolerancesField } from './EditTolerancesField';

const toleranceTypes = {
    COUNT: 1,
    PERCENTAGE: 2,
    RANGE: 3,
};

const isValid = ({ type, value }: { type: number; value: string }) => {
    switch (type) {
        case toleranceTypes.PERCENTAGE:
            return (
                value !== '' &&
                parseInt(value) >= 0 &&
                parseInt(value) <= 999.99
            );

        default:
            return (
                value !== '' && parseInt(value) >= 0 && parseInt(value) <= 99999
            );
    }
};

interface State {
    [key: number]: {
        value: '' | string;
        error: boolean;
        type: number;
    };
}

type Action =
    | { type: 'CHANGE_INPUT'; payload: { id: number; value: string } }
    | { type: 'VALIDATE_INPUT'; payload: { id: number } };

const tolerancesReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'CHANGE_INPUT': {
            const { id, value } = action.payload;
            return {
                ...state,
                [id]: { ...state[id], value },
            };
        }

        case 'VALIDATE_INPUT': {
            const { id } = action.payload;
            return {
                ...state,
                [id]: { ...state[id], error: !isValid(state[id]) },
            };
        }

        default:
            return state;
    }
};

const toToleranceField = (
    id: string,
    type: number,
    value: string,
): { id: string; type: number; value: string; error: boolean } => ({
    id,
    type,
    value,
    error: false,
});

const toTypeString = (type: number): string => {
    switch (type) {
        case 1:
            return 'Count';
        case 2:
            return 'Percentage';
        default:
            return 'Range';
    }
};

const toToleranceFields = (regulators: RegulatorForEdit[]) =>
    regulators
        .flatMap(regulator =>
            regulator.tolerances.flatMap(tolerance => {
                const { id, type, from, to, value } = tolerance;
                if (type === toleranceTypes.RANGE) {
                    return [
                        toToleranceField(`F${id}`, type, from),
                        toToleranceField(`T${id}`, type, to!),
                    ];
                }

                return toToleranceField(`${id}`, type, value);
            }),
        )
        .reduce((output, field) => ({ ...output, [field.id]: field }), {});

const fromToleranceFields = (
    fields: { id: string; value: string; type: number }[],
): { id: string; type: string; value: string }[] =>
    Object.values(
        fields
            .map(({ id, value, type }) => {
                const typeString = toTypeString(type);
                if (id.startsWith('F')) {
                    return { id: id.slice(1), type: typeString, from: value };
                }

                if (id.startsWith('T')) {
                    return { id: id.slice(1), type: typeString, to: value };
                }

                return { id, type: typeString, value };
            })
            .reduce(
                (output, field) => ({
                    ...output,
                    [field.id]: { ...(output[field.id] || {}), ...field },
                }),
                {} as { [key: string]: ToleranceMappedField },
            ),
    );

const editStates = {
    DISPLAY: 1,
    SAVING: 2,
    ERROR: 3,
};

interface EditTolerancesProps {
    spec: SpecificationInterface;
    validations: ValidationInterface[];
    id: number;
    onCancel: () => void;
    onSaved: ({
        specId,
        validation,
    }: {
        specId: number;
        validation: ValidationInterface;
    }) => void;
}

export const EditTolerances = ({
    spec,
    validations,
    id,
    onCancel,
    onSaved,
}: EditTolerancesProps) => {
    const validation = validations.find(v => v.id === id)!;

    const regulators = getRegulatorsForEdit(validation);

    const [fields, dispatch] = useReducer(
        tolerancesReducer,
        toToleranceFields(regulators),
    );

    const handleCancel = useCallback(() => onCancel(), [onCancel]);

    const onToleranceChange = useCallback(
        ({ id, value }: { id: number; value: string }) => {
            dispatch({ type: 'CHANGE_INPUT', payload: { id, value } });
        },
        [],
    );

    const validateInput = useCallback((id: number) => {
        dispatch({ type: 'VALIDATE_INPUT', payload: { id } });
    }, []);

    const getToleranceInput = useCallback((id: number) => fields[id], [fields]);

    const [state, setState] = useState(editStates.DISPLAY);
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        setIsValid(Object.values(fields).every(field => field.error === false));
    }, [fields]);

    const { mutate: updateValidation } = useUpdateValidation();

    const handleSave = async () => {
        if (!isValid) {
            return;
        }

        setState(editStates.SAVING);

        const toleranceFields = fromToleranceFields(Object.values(fields));
        updateValidation(
            {
                specId: spec.id,
                validationId: id,
                tolerances: toleranceFields,
            },
            {
                onSuccess: mutationResponse => {
                    setState(editStates.DISPLAY);
                    onSaved({
                        specId: spec.id,
                        validation: mutationResponse.validation,
                    });
                },
                onError: _error => {
                    setState(editStates.ERROR);
                },
            },
        );
    };

    const isSaving = state === editStates.SAVING;
    const isError = state === editStates.ERROR;

    const api = {
        onToleranceChange,
        getToleranceInput,
        validateInput,
    };

    return (
        <EditForm
            title={'Edit tolerances'}
            subtitle={validation.code}
            onCancel={handleCancel}
            onSave={handleSave}
            isSaving={isSaving}
            error={
                isError
                    ? 'There has been a problem saving your changes. Please try again.'
                    : undefined
            }
            buttons={{
                save: 'Save tolerances',
                saving: 'Saving tolerances...',
            }}
        >
            <EditTolerancesContext.Provider value={api}>
                {regulators.map(regulator => (
                    <EditTolerancesField key={regulator.name} {...regulator} />
                ))}
            </EditTolerancesContext.Provider>
        </EditForm>
    );
};
