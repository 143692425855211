import { combineReducers } from 'redux';
import { spawn } from 'redux-saga/effects';

import { reducer as appReducer, sagas as appSagas } from './app';
import { sagas as authSagas } from './auth';
import {
    reducer as providersReducer,
    sagas as providersSagas,
} from './providers';
import {
    reducer as regulatorReducer,
    sagas as regulatorSagas,
} from './regulators';
import { reducer as streamReducer, sagas as streamSagas } from './streams';
import { reducer as tokensReducer, sagas as tokensSagas } from './tokens';
import { reducer as userReducer, sagas as userSagas } from './user';

export const rootReducer = combineReducers({
    app: appReducer,
    providers: providersReducer,
    regulators: regulatorReducer,
    streams: streamReducer,
    tokens: tokensReducer,
    user: userReducer,
});

export function* rootSaga() {
    yield spawn(providersSagas.listen);
    yield spawn(regulatorSagas.listen);
    yield spawn(tokensSagas.listen);
    yield spawn(appSagas.listen);
    yield spawn(authSagas.listen);
    yield spawn(userSagas.listen);
    yield spawn(streamSagas.listen);
}
