import { Box, Chip, Tooltip } from '@mui/material';
import { RegulatorInterface } from 'queries/specifications/types';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import styles from './regulatorLabels.module.scss';

interface RegulatorLabelProps {
    shortName: string;
    name: string;
}

const RegulatorLabel = ({ shortName, name }: RegulatorLabelProps) => {
    return (
        <Tooltip title={name} arrow={true}>
            <Chip
                className={styles.regulator}
                variant={'outlined'}
                size={'small'}
                label={shortName}
                data-test-id={formatTestId('regulator', shortName)}
            ></Chip>
        </Tooltip>
    );
};

interface RegulatorLabelsProps {
    regulators: RegulatorInterface[];
}

const RegulatorLabels = ({ regulators }: RegulatorLabelsProps) => {
    return (
        regulators.length > 0 && (
            <Box mt={1} mb={1} className={styles.regulators}>
                {regulators.map((regulator, index) => (
                    <RegulatorLabel
                        key={`${regulator.shortName}-${index}`}
                        {...regulator}
                    />
                ))}
            </Box>
        )
    );
};

export default RegulatorLabels;
