import { Box, Paper, Typography } from '@mui/material';

import CreateSpecForm from './CreateSpecForm/CreateSpecForm';

export const NewSpecification = () => (
    <Box component={Paper} p={4} mt={4} maxWidth={500}>
        <Typography variant={'h6'}>New Specification</Typography>
        <Box mt={4}>
            <CreateSpecForm />
        </Box>
    </Box>
);
