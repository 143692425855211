import { Ruleset, RulesetFromApi } from 'queries/rulesets/types';

import { get as fetchGet } from '../utils';

const pipe =
    (
        ...fns: {
            (ruleset: RulesetFromApi): Ruleset;
            (ruleset: RulesetFromApi): Ruleset;
        }[]
    ) =>
    (x: any) =>
        fns.reduce((v, f) => f(v), x);

const addStateName =
    (name: string) =>
    (ruleset: Ruleset): Ruleset => ({
        ...ruleset,
        state: { ...ruleset.state, name },
    });

const addAuditInfo = (ruleset: Ruleset): Ruleset => ({
    ...ruleset,
    createdBy: 'HESA user',
    createdUtc: '2020-08-04T12:00:00Z',
});

const stubRuleset = pipe(addStateName('Available'), addAuditInfo);

const stubRulesets = ({ rulesets }: { rulesets: RulesetFromApi[] }) => ({
    rulesets: rulesets.map(stubRuleset),
});

export interface GetAllRulesetsReturnType {
    rulesets: RulesetFromApi[];
}

export const getAll = ({
    state,
}: {
    state: string[];
}): Promise<GetAllRulesetsReturnType> =>
    fetchGet('/specifications/rulesets', {
        query: { state },
    }).then(stubRulesets);
