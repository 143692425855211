import React from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';

interface EditFormProps {
    title: string;
    subtitle?: string;
    error?: string;
    onCancel: () => void;
    onSave: () => void;
    isSaving: boolean;
    buttons: {
        save: string;
        saving: string;
    };
    children: React.ReactNode;
}

const EditForm = ({
    title,
    subtitle,
    error,
    onCancel,
    onSave,
    isSaving,
    buttons,
    children,
}: EditFormProps) => {
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSave();
    };

    return (
        <Paper square>
            <form onSubmit={handleSubmit}>
                <Box p={3} display={'flex'} flexDirection={'column'}>
                    <Typography variant={'h6'}>{title}</Typography>
                    {subtitle && (
                        <Typography variant={'subtitle1'} paragraph>
                            {subtitle}
                        </Typography>
                    )}
                    {children}
                    <Box
                        mt={2}
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'flex-end'}
                    >
                        <Button
                            onClick={onCancel}
                            disabled={isSaving}
                            data-test-id="cancel"
                        >
                            Cancel
                        </Button>
                        <Box ml={1}>
                            <Button
                                type={'submit'}
                                variant={'contained'}
                                color={'primary'}
                                disabled={isSaving}
                                data-test-id="save"
                            >
                                {isSaving ? buttons.saving : buttons.save}
                            </Button>
                        </Box>
                    </Box>
                    {error && (
                        <Box mt={2}>
                            <Typography color={'error'}>{error}</Typography>
                        </Box>
                    )}
                </Box>
            </form>
        </Paper>
    );
};

export default EditForm;
