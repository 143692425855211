import { useCallback } from 'react';
import {
    Box,
    Link,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import {
    RegulatorTolerance,
    SpecificationInterface,
    ValidationInterface,
} from 'queries/specifications/types';
import { getRegulatorTolerance } from 'queries/specifications/utils';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import MoveSpecificationToDraft from '../MoveSpecificationToDraft/MoveSpecificationToDraft';
import { useEditModal } from '..';

import styles from './regulatorConfigTable.module.scss';

interface RegulatorConfigTableRowProps {
    name: string;
    tolerance: string;
}

const RegulatorConfigTableRow = ({
    name,
    tolerance,
}: RegulatorConfigTableRowProps) => {
    return (
        <TableRow hover className={styles.row}>
            <TableCell>{name}</TableCell>
            <TableCell align={'right'}>{tolerance}</TableCell>
        </TableRow>
    );
};
interface RegulatorConfigTableProps {
    validation: ValidationInterface;
    spec: SpecificationInterface;
}
export const RegulatorConfigTable = ({
    validation,
    spec,
}: RegulatorConfigTableProps) => {
    const { id } = validation;
    const regulators = getRegulatorTolerance(validation);
    // @ts-expect-error TODO: replace edit modal with something simpler
    const { editTolerances } = useEditModal();

    const handleEditTolerances = useCallback(
        () => editTolerances(id),
        [id, editTolerances],
    );

    if (regulators.length === 0) {
        return (
            <Box
                p={3}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
            >
                <Typography variant={'body2'} paragraph>
                    No regulators have been configured for this rule
                </Typography>
            </Box>
        );
    }

    return (
        <>
            <Table
                size={'small'}
                data-test-id={formatTestId('table', 'regulator config')}
            >
                <TableHead>
                    <TableRow className={styles.row}>
                        <TableCell>Regulator</TableCell>
                        <TableCell align={'right'}>Tolerance</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {regulators.map(
                        (regulator: RegulatorTolerance, index: number) => (
                            <RegulatorConfigTableRow
                                key={`${regulator.code}-${index}`}
                                {...regulator}
                            />
                        ),
                    )}
                </TableBody>
            </Table>
            <MoveSpecificationToDraft spec={spec} message={'Edit tolerances?'}>
                <Link
                    component={'button'}
                    variant={'body2'}
                    onClick={handleEditTolerances}
                    data-test-id={formatTestId('edit tolerances')}
                >
                    Edit tolerances
                </Link>
            </MoveSpecificationToDraft>
        </>
    );
};
