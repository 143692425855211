import { useQuery } from '@tanstack/react-query';

import { getUseGetSpecificationByIdQueryOptions } from './options';
import { GetSpecificationByIdQueryOptions } from './types';

export default function useGetSpecificationById(
    queryOptions: GetSpecificationByIdQueryOptions,
) {
    const options = getUseGetSpecificationByIdQueryOptions(queryOptions);

    return useQuery({
        ...options,
        select: data => data,
    });
}
