import { queryOptions } from '@tanstack/react-query';
import { rulesets as rulesetsApi } from 'services/api';

import { GetRulesetsQueryOptions } from './types';

export function getUseRulesetsQueryOptions({ state }: GetRulesetsQueryOptions) {
    return queryOptions({
        queryKey: ['rulesets', `${state.join(',')}`],
        queryFn: () => rulesetsApi.getAll({ state }),
    });
}
