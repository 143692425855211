import { useQuery } from '@tanstack/react-query';

import { getUseSchemasQueryOptions } from './options';
import { GetSchemasQueryOptions } from './types';

export default function useSchemas(queryOptions: GetSchemasQueryOptions) {
    const options = getUseSchemasQueryOptions(queryOptions);

    return useQuery({
        ...options,
        select: data => data.schemas,
    });
}
