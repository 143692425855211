import React from 'react';
import { TABLE_PARAMS_IDS } from 'src/constants/constants';
import useTableParams from 'src/hooks/useTableParams/useTableParams';

import SpecTabPanelContext from './SpecTabPanelContext';

interface ProviderProps {
    children: React.ReactElement;
    validationFilterTerm: string;
    validationPage: number;
    validationRowsPerPage: number;
    derivedFieldsFilterTerm: string;
    derivedFieldPage: number;
    derivedFieldRowsPerPage: number;
}

const SpecTabPanelContextProvider = ({
    children,
    validationFilterTerm,
    validationPage,
    validationRowsPerPage,
    derivedFieldsFilterTerm,
    derivedFieldPage,
    derivedFieldRowsPerPage,
}: ProviderProps) => {
    const DEFAULT_TABLE_PARAMETERS = {
        validationParams: {
            filter: validationFilterTerm,
            page: validationPage,
            rowsPerPage: validationRowsPerPage,
        },
        derivedFieldParams: {
            filter: derivedFieldsFilterTerm,
            page: derivedFieldPage,
            rowsPerPage: derivedFieldRowsPerPage,
        },
    };

    const tableId = TABLE_PARAMS_IDS.SPECIFICATIONS_PANELS;

    const { values: tableParams, methods: tableParamsMethods } = useTableParams(
        tableId,
        DEFAULT_TABLE_PARAMETERS,
    );

    const { validationParams, derivedFieldParams } = tableParams;

    const { setValidationParams, setDerivedFieldParams } = tableParamsMethods;

    const setValidationFilterTerm = (filterTerm: string) => {
        setValidationParams({
            ...validationParams,
            filter: filterTerm,
            page: 0,
        });
    };

    const setDerivedFieldsFilterTerm = (filterTerm: string) => {
        setDerivedFieldParams({
            ...derivedFieldParams,
            filter: filterTerm,
            page: 0,
        });
    };

    const setValidationPage = (page: number) =>
        setValidationParams({ ...validationParams, page });

    const setValidationRowsPerPage = (rowsPerPage: number) =>
        setValidationParams({ ...validationParams, rowsPerPage, page: 0 });

    const setDerivedFieldPage = (page: number) =>
        setDerivedFieldParams({ ...derivedFieldParams, page });

    const setDerivedFieldRowsPerPage = (rowsPerPage: number) =>
        setDerivedFieldParams({ ...derivedFieldParams, rowsPerPage, page: 0 });

    const pageContext = {
        validationParams,
        setValidationPage,
        setValidationRowsPerPage,
        setValidationFilterTerm,

        derivedFieldParams,
        setDerivedFieldPage,
        setDerivedFieldRowsPerPage,
        setDerivedFieldsFilterTerm,
    };

    return (
        <SpecTabPanelContext.Provider value={pageContext}>
            {children}
        </SpecTabPanelContext.Provider>
    );
};

SpecTabPanelContextProvider.defaultProps = {
    validationPage: 0,
    validationRowsPerPage: 10,
    validationFilterTerm: '',

    derivedFieldPage: 0,
    derivedFieldRowsPerPage: 10,
    derivedFieldsFilterTerm: '',
};

export default SpecTabPanelContextProvider;
