import { useMutation, useQueryClient } from '@tanstack/react-query';
import { specifications as specificationsApi } from 'services/api';

import { CreateSpecificationArgs } from './types';

export default function useCreateSpecification() {
    const queryClient = useQueryClient();

    const { mutate, ...mutation } = useMutation({
        mutationFn: (args: CreateSpecificationArgs) =>
            specificationsApi.create(args),
        onSettled: mutationResponse => {
            queryClient.setQueryData(
                ['specifications', ''],
                (previousData: any) => {
                    return {
                        ...previousData,
                        specifications: [
                            mutationResponse,
                            ...previousData.specifications,
                        ],
                    };
                },
            );

            queryClient.invalidateQueries({
                queryKey: ['specifications', ''],
            });
        },
    });

    return { mutate, ...mutation };
}
