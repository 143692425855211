import React from 'react';
import { Radio } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';

import styles from './radioButtonWithDescription.module.scss';
interface RadioButtonWithDescriptionProps {
    value: string;
    label: string;
    isDisabled?: boolean;
    children: React.ReactNode;
    testId: string;
}

const RadioButtonWithDescription = ({
    value,
    label,
    isDisabled = false,
    children,
    testId,
}: RadioButtonWithDescriptionProps) => {
    return (
        <FormControlLabel
            className={styles.root}
            value={value}
            control={
                <Radio
                    className={styles.radio}
                    size={'small'}
                    color={'default'}
                />
            }
            label={
                <>
                    <strong className={styles.label}>{label}</strong>
                    {children}
                </>
            }
            disabled={isDisabled}
            data-test-id={testId}
        />
    );
};

export default RadioButtonWithDescription;
