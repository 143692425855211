import { useMutation, useQueryClient } from '@tanstack/react-query';
import { specifications as specificationsApi } from 'services/api';

import { ApplySpecConfigArgs } from './types';

export default function useApplySpecConfig() {
    const queryClient = useQueryClient();

    const { mutate, ...mutation } = useMutation({
        mutationFn: (args: ApplySpecConfigArgs) =>
            specificationsApi.applyConfiguration(args),
        onSuccess: (mutationResponse, variables: ApplySpecConfigArgs) => {
            queryClient.invalidateQueries({
                queryKey: ['specifications', ''],
            });
            queryClient.invalidateQueries({
                queryKey: ['specification', `${variables.id}`],
            });
        },
    });

    return { mutate, ...mutation };
}
