import React from 'react';
import {
    FormControl,
    FormHelperText,
    FormLabel,
    NativeSelect,
} from '@mui/material';
import { SchemasAndRulesetsReturn } from 'queries/specifications/types';
import { formatTestId } from 'utils/formatTestId/formatTestId';

interface SchemaDropdownProps {
    isDisabled: boolean;
    fields: any;
    data: Pick<SchemasAndRulesetsReturn, 'data'>['data'];
}

const SchemaDropdown = ({
    isDisabled = false,
    fields,
    data,
}: SchemaDropdownProps) => {
    const { onChange, value, error } = fields.schemaId;

    const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        onChange(e.target.value ? parseInt(e.target.value as string, 10) : '');
    };
    const options = data
        .map(({ schema, rulesets }) => {
            const isOptionDisabled = rulesets.length === 0;

            return {
                name: schema.version,
                value: schema.id,
                index: schema.index,
                isOptionDisabled,
            };
        })
        .sort((a, b) => {
            if (a.isOptionDisabled === b.isOptionDisabled) {
                return a.index - b.index;
            }

            return a.isOptionDisabled ? 1 : -1;
        });

    const defaultOption = {
        value: '',
        name: 'Choose',
        isOptionDisabled: false,
    };
    return (
        <FormControl disabled={isDisabled} error={error}>
            <FormLabel>Schema version</FormLabel>
            <NativeSelect
                onChange={handleChange}
                value={value}
                data-test-id={formatTestId('openMenu', 'schemaVersion')}
                inputProps={{
                    'aria-label': 'Schema version',
                }}
            >
                {[defaultOption, ...options].map(option => (
                    <option
                        key={option.name}
                        value={option.value}
                        disabled={option.isOptionDisabled}
                        data-test-id={formatTestId('schemaOption', option.name)}
                    >
                        {option.isOptionDisabled
                            ? `${option.name} (No rulesets available)`
                            : option.name}
                    </option>
                ))}
            </NativeSelect>

            {error && <FormHelperText>Choose a schema version</FormHelperText>}
        </FormControl>
    );
};

export default SchemaDropdown;
