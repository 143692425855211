import { Box, Chip, Grid, Paper, Typography } from '@mui/material';
import { SpecificationInterface } from 'queries/specifications/types';
import { formatTestId } from 'utils/formatTestId/formatTestId';
interface SpecInfoProps {
    spec: SpecificationInterface;
}

const SpecInfo = ({ spec }: SpecInfoProps) => {
    const { version, state, schema, ruleset } = spec;

    return (
        <Paper square>
            <Box p={3}>
                <Box
                    mb={6}
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <Typography
                        variant={'h5'}
                        id="heading"
                    >{`Specification ${version}`}</Typography>
                    <Chip
                        label={state.name}
                        variant={'outlined'}
                        data-test-id={formatTestId('specification state')}
                    />
                </Box>
                <Grid container>
                    <Grid item xs={6}>
                        <Typography>Schema</Typography>
                        <Typography variant={'body2'}>
                            {schema.version}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>Ruleset</Typography>
                        <Typography variant={'body2'}>
                            {ruleset.version}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
};

export default SpecInfo;
