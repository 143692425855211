import { useQuery } from '@tanstack/react-query';

import { getUseRulesetsQueryOptions } from './options';
import { GetRulesetsQueryOptions } from './types';

export default function useRulesets(queryOptions: GetRulesetsQueryOptions) {
    const options = getUseRulesetsQueryOptions(queryOptions);

    return useQuery({
        ...options,
        select: data => {
            return data.rulesets;
        },
    });
}
