import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    FormControl,
    Grid,
    Paper,
    RadioGroup,
    Typography,
} from '@mui/material';
import RadioButtonWithDescription from 'components/RadioButtonWithDescription/RadioButtonWithDescription';
import useApplySpecConfig from 'queries/specifications/useApplySpecConfig';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import styles from './applyConfigurationForm.module.scss';

type Status = 'idle' | 'pending' | 'success' | 'error';

interface ApplyConfigurationFormProps {
    specId: number;
}

export const ApplyConfigurationForm = ({
    specId,
}: ApplyConfigurationFormProps) => {
    const [status, setStatus] = useState<Status>('idle');

    const { mutate: applyConfig } = useApplySpecConfig();
    const navigate = useNavigate();

    const handleApplyConfig = () => {
        setStatus('pending');
        applyConfig(
            { id: specId },
            {
                onSuccess: () => {
                    navigate('/management/specifications');
                },
                onError: () => {
                    setStatus('error');
                },
            },
        );
    };

    return (
        <Paper square>
            <Box p={3} mt={3}>
                <Typography variant={'h6'} paragraph>
                    Rule configuration
                </Typography>

                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <FormControl component={'fieldset'}>
                            <RadioGroup defaultValue="ruleDefaults">
                                <RadioButtonWithDescription
                                    value={'ruleDefaults'}
                                    label={'Use rule defaults'}
                                    testId={formatTestId(
                                        'option',
                                        'ruleDefaults',
                                    )}
                                >
                                    Copy the rule configuration from the rule
                                    defaults
                                </RadioButtonWithDescription>
                                <RadioButtonWithDescription
                                    value={'otherSpecification'}
                                    label={
                                        'Use configuration from another collection'
                                    }
                                    testId={formatTestId(
                                        'option',
                                        'otherSpecification',
                                    )}
                                    isDisabled
                                >
                                    Copy the rule configuration from a previous
                                    collection
                                </RadioButtonWithDescription>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            className={styles.button}
                            variant={'contained'}
                            color={'primary'}
                            size={'small'}
                            disabled={status === 'pending'}
                            disableElevation
                            onClick={handleApplyConfig}
                            data-test-id="applyConfiguration"
                        >
                            {status === 'pending'
                                ? 'Applying...'
                                : 'Apply configuration'}
                        </Button>{' '}
                    </Grid>
                    {status === 'error' && (
                        <Grid item xs={12}>
                            <Typography color={'error'}>
                                Sorry, there has been a problem applying the
                                configuration. Please try again.
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Paper>
    );
};

export default ApplyConfigurationForm;
