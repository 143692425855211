import { Box, Button, Paper, TablePagination, Typography } from '@mui/material';
import Loading from 'components/Loading/Loading';
import {
    SpecificationInterface,
    ValidationInterface,
} from 'queries/specifications/types';
import { HDPAccordionGroup } from 'src/components/HDPAccordion';

import { FilterForm } from '..';

import EditModal from './EditModal/EditModal';
import Validation from './Validation/Validation';

interface LoadingFailedProps {
    onTryAgain: () => void;
}

const LoadingFailed = ({ onTryAgain }: LoadingFailedProps) => (
    <Paper>
        <Box
            p={4}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
        >
            <Typography variant={'body2'} paragraph>
                There was a problem loading the list of validations.
            </Typography>
            <Button variant={'outlined'} size={'small'} onClick={onTryAgain}>
                Try again
            </Button>
        </Box>
    </Paper>
);

interface ValidationsProps {
    spec: SpecificationInterface;
    data: { validations: ValidationInterface[]; pagingMetadata: any };
    handleChangeRowsPerPage: any;
    handleChangePage: any;
    handleFilter: any;
    handleTryAgain: any;
    validationParams: any;
    status: 'pending' | 'error' | 'success';
}

const Validations = ({
    spec,
    data,
    handleChangeRowsPerPage,
    handleChangePage,
    handleFilter,
    handleTryAgain,
    validationParams,
    status,
}: ValidationsProps) => {
    const validations = data?.validations.sort((a, b) => {
        return a.code < b.code ? -1 : 1;
    });

    return (
        <EditModal spec={spec} validations={validations}>
            <Paper square>
                <Box p={3}>
                    <FilterForm
                        initialFilterTerm={validationParams.filter}
                        onFilter={handleFilter}
                    />
                </Box>
            </Paper>
            {status === 'pending' && <Loading />}
            {status === 'error' && (
                <LoadingFailed onTryAgain={handleTryAgain} />
            )}
            {status === 'success' && (
                <>
                    {validationParams.filter && (
                        <Paper square>
                            <Box px={3} pb={2}>
                                <Typography variant={'body2'}>
                                    <strong>
                                        {data.pagingMetadata.filteredTotal}
                                    </strong>{' '}
                                    validation
                                    {data.pagingMetadata.filteredTotal === 1
                                        ? ' rule'
                                        : ' rules'}{' '}
                                    found matching{' '}
                                    <strong>{validationParams.filter}</strong>
                                </Typography>
                            </Box>
                        </Paper>
                    )}
                    {/* @ts-expect-error TODO: fix this when Accordion is replaced*/}
                    <HDPAccordionGroup spread={false}>
                        {validations.map((validation, index) => (
                            <Validation
                                key={`validation-${validation.id}-${index}`}
                                validation={validation}
                                spec={spec}
                            />
                        ))}
                    </HDPAccordionGroup>
                    {/* @ts-expect-error TODO fix when accordion is replaced */}
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={data.pagingMetadata.filteredTotal}
                        rowsPerPage={validationParams.rowsPerPage}
                        page={validationParams.page}
                        backIconButtonProps={{
                            'aria-label': 'previous page',
                            'data-test-id': 'previousPage',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'next page',
                            'data-test-id': 'nextPage',
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            )}
        </EditModal>
    );
};

export default Validations;
