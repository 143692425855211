import React, { useCallback } from 'react';
import { InputAdornment, TextField, Typography } from '@mui/material';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import { useEditTolerances } from '../useEditTolerances';

import styles from './editTolerancesField.module.scss';

const toleranceTypes = {
    COUNT: 1,
    PERCENTAGE: 2,
    RANGE: 3,
};

const conjunctionTypes = {
    O: 'or',
    A: 'and',
};

const NumberInput = ({
    id,
    suffix = null,
    inputType = 'number',
    helperText,
    testId,
}) => {
    const { onToleranceChange, getToleranceInput, validateInput } =
        useEditTolerances();
    const { error, value } = getToleranceInput(id);

    const resolveToleranceValue = useCallback(
        value => {
            switch (inputType) {
                case 'text':
                    return value
                        ? value
                              .toString()
                              .split('.')
                              .map((el, i) =>
                                  i ? el.split('').slice(0, 2).join('') : el,
                              )
                              .join('.')
                        : '';
                default:
                    return value ? Number.parseInt(value, 10) : '';
            }
        },
        [inputType],
    );

    const handleChange = useCallback(
        e => {
            onToleranceChange({
                id,
                value: resolveToleranceValue(e.target.value),
            });
        },
        [onToleranceChange, resolveToleranceValue, id],
    );

    const handleBlur = useCallback(
        () => validateInput(id),
        [validateInput, id],
    );

    return React.useMemo(() => {
        const InputProps = {
            ...(suffix
                ? {
                      endAdornment: (
                          <InputAdornment position={'end'}>
                              {suffix}
                          </InputAdornment>
                      ),
                  }
                : {}),
        };

        return (
            <TextField
                className={styles.textField}
                id={id}
                type={inputType}
                error={error}
                helperText={error && helperText}
                InputProps={InputProps}
                size={'small'}
                variant={'outlined'}
                onChange={handleChange}
                onBlur={handleBlur}
                value={value}
                data-test-id={testId}
            />
        );
    }, [
        error,
        handleBlur,
        handleChange,
        id,
        value,
        inputType,
        suffix,
        helperText,
        testId,
    ]);
};

const To = () => {
    return <span className={styles.separatorTo}>to</span>;
};

const Conjunction = ({ type }) => {
    return (
        <span className={styles.separatorConjunction}>
            {conjunctionTypes[type]}
        </span>
    );
};

const ToleranceCount = ({ id, type, value }) => (
    <>
        <label htmlFor={id} className="scr-only">
            Tolerance count
        </label>
        <NumberInput
            id={`${id}`}
            type={type}
            value={value}
            helperText={'0 to 99999'}
            testId={formatTestId('input', 'toleranceCount')}
        />
    </>
);

const TolerancePercentage = ({ id, type, value }) => (
    <>
        <label className="scr-only" htmlFor={id}>
            Tolerance percentage
        </label>
        <NumberInput
            id={`${id}`}
            type={type}
            value={value}
            suffix={'%'}
            inputType={'text'}
            helperText={'0 to 999.99'}
            testId={formatTestId('input', 'tolerancePercentage')}
        />
    </>
);

const ToleranceRange = ({ id, type, from, to }) => [
    <>
        <label className="scr-only" htmlFor={`F${id}`}>
            Tolerance range start
        </label>
        ,
        <NumberInput
            id={`F${id}`}
            key={'from'}
            type={type}
            value={from}
            helperText={'0 to 99999'}
            testId={formatTestId('input', 'toleranceRangeFrom')}
        />
        ,
        <To key={'dash'} />,
        <label className="scr-only" htmlFor={`T${id}`}>
            Tolerance range end
        </label>
        ,
        <NumberInput
            id={`T${id}`}
            key={'to'}
            type={type}
            value={to}
            helperText={'0 to 99999'}
            testId={formatTestId('input', 'toleranceRangeTo')}
        />
        ,
    </>,
];

const Tolerances = ({ conjunction, tolerances }) => {
    const renderTolerance = tolerance => {
        switch (tolerance.type) {
            case toleranceTypes.COUNT:
                return <ToleranceCount key={tolerance.type} {...tolerance} />;

            case toleranceTypes.PERCENTAGE:
                return (
                    <TolerancePercentage key={tolerance.type} {...tolerance} />
                );

            case toleranceTypes.RANGE:
                return <ToleranceRange key={tolerance.type} {...tolerance} />;

            default:
                return null;
        }
    };

    const addConjunction = type => (output, tolerance) =>
        [output, <Conjunction key={type} type={type} />, tolerance];

    return (
        <div className={styles.tolerances}>
            {tolerances
                .map(renderTolerance)
                .reduce(addConjunction(conjunction))}
        </div>
    );
};

export const EditTolerancesField = React.memo(
    ({ name, conjunction, tolerances }) => {
        return (
            <div className={styles.field}>
                <div className={styles.name}>
                    <Typography variant={'body1'}>{name}</Typography>
                </div>
                <Tolerances conjunction={conjunction} tolerances={tolerances} />
            </div>
        );
    },
);
