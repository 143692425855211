import React, { useEffect, useState } from 'react';
import { Box, Chip, NativeSelect } from '@mui/material';
import { specStates } from 'constants/specifications';
import { SpecificationInterface } from 'queries/specifications/types';
import useUpdateSpecificationState from 'queries/specifications/useUpdateSpecificationState';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import {
    CancelButton,
    EditButton,
    SaveButton,
} from '../../../Collections/Buttons';

interface SpecStatusProps {
    id: number;
    version: string;
}

const SpecStatus = ({ id, version }: SpecStatusProps) => {
    if (!id || !version) return null;

    switch (id) {
        case specStates.CREATED:
            return (
                <Chip
                    variant={'outlined'}
                    label={'Created'}
                    data-test-id={formatTestId(
                        'status',
                        `specification ${version}`,
                    )}
                />
            );

        case specStates.DRAFT:
            return (
                <Chip
                    variant={'outlined'}
                    label={'Draft'}
                    data-test-id={formatTestId(
                        'status',
                        `specification ${version}`,
                    )}
                />
            );

        case specStates.AVAILABLE:
            return (
                <Chip
                    variant={'outlined'}
                    label={'Available'}
                    data-test-id={formatTestId(
                        'status',
                        `specification ${version}`,
                    )}
                />
            );

        case specStates.IN_USE:
            return (
                <Chip
                    variant={'outlined'}
                    label={'In Use'}
                    data-test-id={formatTestId(
                        'specStatus',
                        `specification ${version}`,
                    )}
                />
            );

        default:
            return null;
    }
};

const modes = {
    DISPLAY: 1,
    EDITING: 2,
    SAVING: 3,
    ERROR: 4,
};

interface SpecStateProps {
    specification: SpecificationInterface;
}

export const SpecState = ({ specification }: SpecStateProps) => {
    const { DISPLAY, EDITING, SAVING, ERROR } = modes;
    const { id, version, availableStates } = specification;
    const isEditable = availableStates.length > 0 ? true : false;
    const { mutate: updateSpecificationState } = useUpdateSpecificationState();

    const [mode, setMode] = useState(DISPLAY);

    const defaultChoice = { id: '', name: 'Select state' };

    const [value, setValue] = useState(defaultChoice.id);

    useEffect(() => {
        if (mode === DISPLAY) {
            setValue(defaultChoice.id);
        }
    }, [DISPLAY, defaultChoice.id, mode]);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setValue(event.target.value as string);
    };

    const handleEdit = () => setMode(EDITING);
    const handleCancel = () => {
        setMode(DISPLAY);
    };
    const handleSave = () => {
        setMode(SAVING);
        updateSpecificationState(
            {
                stateId: Number(value),
                id: id,
            },

            {
                onSuccess: () => {
                    setMode(DISPLAY);
                },
                onError: () => {
                    setMode(ERROR);
                },
            },
        );
    };

    const renderDropdown = ({ disabled = false, error = false } = {}) => (
        <NativeSelect
            id={formatTestId('select', `specification ${version}`)}
            value={value}
            onChange={handleChange}
            disabled={disabled}
            error={error}
            inputProps={{
                'aria-label': 'Specification status',
            }}
        >
            {[defaultChoice, ...availableStates].map(({ id, name }) => (
                <option key={id} value={id}>
                    {name}
                </option>
            ))}
        </NativeSelect>
    );

    const renderEditButton = () => (
        <EditButton
            title="Change specification state"
            onClick={handleEdit}
            data-test-id={formatTestId('editState', `specification ${version}`)}
        />
    );

    const renderSaveButton = ({ loading = false, disabled = false } = {}) => (
        <SaveButton
            title="Save specification state"
            onClick={handleSave}
            loading={loading}
            disabled={loading || disabled}
            data-test-id={formatTestId('saveState', `specification ${version}`)}
        />
    );

    const renderCancelButton = ({ disabled = false } = {}) => (
        <CancelButton
            title="Cancel"
            onClick={handleCancel}
            disabled={disabled}
            data-test-id={formatTestId(
                'cancelEdit',
                `specification ${version}`,
            )}
        />
    );

    interface WrapperProps {
        children: React.ReactNode;
        justifyContent?: 'flex-start' | 'space-between';
    }

    const Wrapper = ({
        children,
        justifyContent = 'space-between',
    }: WrapperProps) => (
        <Box
            display={'flex'}
            justifyContent={justifyContent}
            alignItems={'center'}
        >
            {children}
        </Box>
    );

    switch (mode) {
        case EDITING:
            return (
                <Wrapper>
                    {renderDropdown()}
                    <Box>
                        {renderSaveButton({
                            disabled: value === defaultChoice.id,
                        })}
                        {renderCancelButton({})}
                    </Box>
                </Wrapper>
            );

        case SAVING:
            return (
                <Wrapper>
                    {renderDropdown({ disabled: true })}
                    <Box>
                        {renderSaveButton({ loading: true })}
                        {renderCancelButton({
                            disabled: true,
                        })}
                    </Box>
                </Wrapper>
            );

        case ERROR:
            return (
                <Wrapper>
                    {renderDropdown({ error: true })}
                    <Box>
                        {renderSaveButton()}
                        {renderCancelButton()}
                    </Box>
                </Wrapper>
            );

        default:
            return (
                <Wrapper justifyContent={'flex-start'}>
                    <SpecStatus id={specification.state.id} version={version} />
                    {isEditable && renderEditButton()}
                </Wrapper>
            );
    }
};
