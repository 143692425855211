import styles from './collectionSummary.module.scss';

function getStatusTagStyle(status: string) {
    if (status === 'Open') {
        return `${styles.statusTag} ${styles.statusTagOpen}`;
    }

    if (status === 'Closed') {
        return `${styles.statusTag} ${styles.statusTagClosed}`;
    }

    if (status === 'Historic amendment') {
        return `${styles.statusTag} ${styles.statusTagHistoricAmendment}`;
    }

    if (status === 'Archived') {
        return `${styles.statusTag} ${styles.statusTagArchived}`;
    }

    if (['Draft', 'Validation'].includes(status)) {
        return `${styles.statusTag} ${styles.statusTagDraft}`;
    }

    // Deleted and Closed both return this
    return `${styles.statusTag} ${styles.statusTagOther}`;
}

function getReferencePeriodTagStyle(status: string) {
    if (['Open', 'Draft', 'Validation'].includes(status)) {
        return styles.referencePeriodTagActive;
    }

    return styles.referencePeriodTagNotActive;
}

interface CollectionSummaryProps {
    referencePeriodStart: string;
    referencePeriodEnd: string;
    status: string;
}

const CollectionSummary = ({
    referencePeriodStart,
    referencePeriodEnd,
    status,
}: CollectionSummaryProps) => {
    return (
        <span className={styles.collectionSummary}>
            <span className={styles.referencePeriod}>
                Reference period
                <span className={getReferencePeriodTagStyle(status)}>
                    {referencePeriodStart} - {referencePeriodEnd}
                </span>
            </span>
            <span className={styles.status}>
                Status
                <span className={getStatusTagStyle(status)}>{status}</span>
            </span>
        </span>
    );
};

export default CollectionSummary;
