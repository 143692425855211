import React, { useEffect, useRef, useState } from 'react';
import { Box, Link } from '@mui/material';
import { specStates } from 'constants/specifications';
import { SpecificationInterface } from 'queries/specifications/types';
import useUpdateSpecificationState from 'queries/specifications/useUpdateSpecificationState';

const Wrapper = ({ children }: { children: React.ReactNode }) => (
    <Box
        px={3}
        pt={1}
        pb={2}
        display={'flex'}
        justifyContent={'flex-end'}
        alignItems={'center'}
    >
        {children}
    </Box>
);

const states = {
    DISPLAY: 1,
    UPDATING: 2,
    ERROR: 3,
};

interface EditInAvailableProps {
    spec: SpecificationInterface;
    message: string;
}

const EditInAvailable = ({ spec, message }: EditInAvailableProps) => {
    const [state, setState] = useState(states.DISPLAY);
    const { mutate: updateSpecState } = useUpdateSpecificationState();
    const mounted = useRef(true);

    useEffect(() => {
        return () => {
            mounted.current = false;
        };
    }, []);

    const moveToDraft = () => {
        setState(states.UPDATING);

        updateSpecState(
            { id: spec.id, stateId: specStates.DRAFT },
            {
                onSuccess: () => {
                    if (mounted.current) {
                        setState(states.DISPLAY);
                    }
                },
                onError: () => setState(states.ERROR),
            },
        );
    };

    switch (state) {
        case states.UPDATING:
            return (
                <Wrapper>
                    <span
                        style={{
                            marginRight: 8,
                            color: '#999',
                            cursor: 'not-allowed',
                        }}
                    >
                        {message}
                    </span>
                    <span
                        style={{
                            color: '#999',
                        }}
                    >
                        Moving specification to draft...
                    </span>
                </Wrapper>
            );

        case states.ERROR:
            return (
                <Wrapper>
                    <span
                        style={{
                            marginRight: 8,
                            color: '#999',
                        }}
                    >
                        There was an error moving the specification to draft.
                    </span>
                    <Link
                        component={'button'}
                        variant={'body2'}
                        onClick={moveToDraft}
                    >
                        Try again
                    </Link>
                </Wrapper>
            );

        default:
            return (
                <Wrapper>
                    <span
                        style={{
                            marginRight: 8,
                            color: '#999',
                            cursor: 'not-allowed',
                        }}
                    >
                        {message}
                    </span>
                    <Link
                        component={'button'}
                        variant={'body2'}
                        onClick={moveToDraft}
                    >
                        Move specification to draft
                    </Link>
                </Wrapper>
            );
    }
};

interface MoveSpecificationToDraftProps {
    spec: SpecificationInterface;
    message: string;
    children: React.ReactNode;
}

const MoveSpecificationToDraft = ({
    spec,
    message,
    children,
}: MoveSpecificationToDraftProps) => {
    switch (spec.state.id) {
        case specStates.DRAFT:
            return <Wrapper>{children}</Wrapper>;

        case specStates.AVAILABLE:
            return <EditInAvailable message={message} spec={spec} />;

        default:
            return null;
    }
};

export default MoveSpecificationToDraft;
