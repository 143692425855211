import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { Collection } from 'services/api/collections/types';
import { SpecificationInterface } from 'src/queries/specifications/types';
import { formatTestId } from 'utils/formatTestId/formatTestId';

import CollectionsTableRow from './CollectionsTableRow';

import styles from './collectionsTable.module.scss';

interface CollectionsTableProps {
    collections: Collection[];
    specifications: SpecificationInterface[];
    limit: number;
    offset: number;
}

const getSpecificationForCollection = (
    specifications: SpecificationInterface[],
    collection: Collection,
) => {
    return specifications.find(
        specification => specification.id === collection.specification.id,
    );
};

const CollectionsTable = ({
    collections,
    specifications,
    limit,
    offset,
}: CollectionsTableProps) => {
    return (
        <Box mb={4}>
            <TableContainer component={Paper}>
                <Table
                    size={'small'}
                    data-test-id={formatTestId('table', 'collections')}
                >
                    <TableHead>
                        <TableRow className={styles.header}>
                            <TableCell
                                className={styles.headerCell}
                                width="10%"
                            >
                                Reference
                            </TableCell>
                            <TableCell className={styles.headerCell}>
                                Name
                            </TableCell>
                            <TableCell className={styles.headerCell}>
                                Reference Period
                            </TableCell>
                            <TableCell className={styles.headerCell}>
                                Specification
                            </TableCell>
                            <TableCell
                                className={styles.headerCell}
                                width="25%"
                            >
                                State
                            </TableCell>
                            <TableCell>&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {collections.map(collection => (
                            <CollectionsTableRow
                                key={collection.reference}
                                collection={collection}
                                limit={limit}
                                offset={offset}
                                specification={getSpecificationForCollection(
                                    specifications,
                                    collection,
                                )}
                                specifications={specifications}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default CollectionsTable;
