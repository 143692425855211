import { Box, Grid, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { SpecificationInterface } from 'queries/specifications/types';

interface SpecAuditInfoProps {
    spec: SpecificationInterface;
}

const SpecAuditInfo = ({ spec }: SpecAuditInfoProps) => {
    const { updatedUtc, updatedBy, createdUtc, createdBy } = spec;

    const formatDate = (date: string) =>
        DateTime.fromISO(date).toFormat('dd/LL/yyyy HH:mm');

    return (
        <Box>
            <Grid container component={'dl'} direction={'row'} spacing={2}>
                <Grid item xs={12} sm={6} md={12}>
                    <Typography component={'dt'} variant={'subtitle2'}>
                        Last updated
                    </Typography>
                    <Typography component={'dd'} variant={'body2'}>
                        {formatDate(updatedUtc)}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={12}>
                    <Typography component={'dt'} variant={'subtitle2'}>
                        Last updated by
                    </Typography>
                    <Typography component={'dd'} variant={'body2'}>
                        {updatedBy}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={12}>
                    <Typography component={'dt'} variant={'subtitle2'}>
                        Created
                    </Typography>
                    <Typography component={'dd'} variant={'body2'}>
                        {formatDate(createdUtc)}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={12}>
                    <Typography component={'dt'} variant={'subtitle2'}>
                        Created by
                    </Typography>
                    <Typography component={'dd'} variant={'body2'}>
                        {createdBy}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SpecAuditInfo;
