import { useMutation, useQueryClient } from '@tanstack/react-query';
import { specifications as specificationsApi } from 'services/api';

import { UpdateValidationArgs } from './types';

export default function useUpdateValidation() {
    const queryClient = useQueryClient();

    const { mutate, ...mutation } = useMutation({
        mutationFn: (args: UpdateValidationArgs) =>
            specificationsApi.updateValidation(args),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['validations'],
            });
        },
    });

    return { mutate, ...mutation };
}
