import { useQuery } from '@tanstack/react-query';

import { getUseValidationsQueryOptions } from './options';
import { ValidationsQueryOptions } from './types';

export default function useValidations(queryOptions: ValidationsQueryOptions) {
    const options = getUseValidationsQueryOptions(queryOptions);

    return useQuery({
        ...options,

        select: data => data,
    });
}
