import { queryOptions } from '@tanstack/react-query';
import { specifications as specificationsApi } from 'services/api';

import {
    GetSpecificationByIdQueryOptions,
    SpecificationsQueryOptions,
    ValidationsQueryOptions,
} from './types';

export function getUseSpecificationsQueryOptions({
    state,
}: SpecificationsQueryOptions) {
    return queryOptions({
        queryKey: ['specifications', `${state.join(',')}`],
        queryFn: () => specificationsApi.getAll({ state }),
    });
}

export function getUseGetSpecificationByIdQueryOptions({
    id,
}: GetSpecificationByIdQueryOptions) {
    return queryOptions({
        queryKey: ['specification', `${id}`],
        queryFn: () => specificationsApi.get({ id }),
    });
}

export function getUseValidationsQueryOptions({
    id,
    filter,
    offset,
    limit,
}: ValidationsQueryOptions) {
    return queryOptions({
        queryKey: ['validations', id, filter, offset, limit],
        queryFn: () =>
            specificationsApi.getValidations({ id, filter, offset, limit }),
    });
}

export function getUseDerivedFieldsQueryOptions({
    id,
    filter,
    offset,
    limit,
}: ValidationsQueryOptions) {
    return queryOptions({
        queryKey: ['derivedFields', id, filter, offset, limit],
        queryFn: () =>
            specificationsApi.getDerivedFields({ id, filter, offset, limit }),
    });
}
