import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
    Collection,
    UpdateSpecificationArgs,
} from 'services/api/collections/types';
import { updateSpecification } from 'src/services/api/collections/collections';

export default function useUpdateSpecification() {
    const queryClient = useQueryClient();

    const { mutate, ...mutation } = useMutation({
        mutationFn: (args: UpdateSpecificationArgs) =>
            updateSpecification(args),
        onSettled: (mutationResponse, _error, variables) => {
            queryClient.setQueryData(
                ['collections', variables.limit, variables.offset],
                (previousData: any) => {
                    return {
                        ...previousData,
                        collections: previousData.collections.map(
                            (collection: Collection) => {
                                if (
                                    collection.reference ===
                                    mutationResponse.reference
                                ) {
                                    const oldCollection = collection;
                                    const newCollection = {
                                        ...oldCollection,
                                        ...mutationResponse,
                                    };
                                    return newCollection;
                                }
                                return collection;
                            },
                        ),
                    };
                },
            );
            queryClient.invalidateQueries({
                queryKey: ['collections', variables.limit, variables.offset],
            });
        },
    });

    return { mutate, ...mutation };
}
