import React, { useEffect, useState } from 'react';
import { Box, Chip, NativeSelect } from '@mui/material';
import { Schema } from 'queries/schemas/types';
import useUpdateSchemaState from 'queries/schemas/useUpdateSchemaState';

import {
    CancelButton,
    EditButton,
    SaveButton,
} from '../../Collections/Buttons';

const modes = {
    DISPLAY: 1,
    EDITING: 2,
    SAVING: 3,
    ERROR: 4,
};

const { DISPLAY, EDITING, SAVING, ERROR } = modes;

const getSchemaState = (schema: Schema) => {
    const isEditable = schema.availableStates.length > 0;

    return {
        state: schema.state,
        availableStates: schema.availableStates,
        isEditable,
    };
};

interface SchemaStateProps {
    schema: Schema;
}

const SchemaState = ({ schema }: SchemaStateProps) => {
    const { state, availableStates, isEditable } = getSchemaState(schema);
    const [mode, setMode] = useState(DISPLAY);

    const defaultChoice = { id: '', name: 'Select state' };
    const { mutate: updateSchemaState } = useUpdateSchemaState();

    const [value, setValue] = useState(defaultChoice.id);

    useEffect(() => {
        if (mode === DISPLAY) {
            setValue(defaultChoice.id);
        }
    }, [defaultChoice.id, mode]);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setValue(event.target.value as string);
    };

    const handleEdit = () => setMode(EDITING);
    const handleSave = () => {
        updateSchemaState(
            {
                id: schema.id,
                stateId: value,
            },
            {
                onSuccess: () => {
                    setMode(DISPLAY);
                },
                onError: () => {
                    setMode(ERROR);
                },
            },
        );
    };

    const handleCancel = () => setMode(DISPLAY);

    const renderDropdown = ({ disabled = false, error = false } = {}) => (
        <NativeSelect
            value={value}
            onChange={handleChange}
            disabled={disabled}
            error={error}
            inputProps={{
                'aria-label': 'States',
            }}
        >
            {[defaultChoice, ...availableStates].map(({ id, name }) => (
                <option key={id} value={id}>
                    {name}
                </option>
            ))}
        </NativeSelect>
    );

    const renderEditButton = () => (
        <EditButton title="Change schema state" onClick={handleEdit} />
    );

    const renderSaveButton = ({ loading = false, disabled = false } = {}) => (
        <SaveButton
            title="Save collection state"
            onClick={handleSave}
            loading={loading}
            disabled={loading || disabled}
        />
    );

    const renderCancelButton = ({ disabled = false } = {}) => (
        <CancelButton
            title="Cancel"
            onClick={handleCancel}
            disabled={disabled}
        />
    );

    const Wrapper = ({
        children,
        justifyContent = 'space-between',
    }: {
        children: React.ReactNode;
        justifyContent?: 'space-between' | 'flex-start';
    }) => (
        <Box
            display={'flex'}
            justifyContent={justifyContent}
            alignItems={'center'}
        >
            {children}
        </Box>
    );

    switch (mode) {
        case EDITING:
            return (
                <Wrapper>
                    {renderDropdown()}
                    <Box>
                        {renderSaveButton({
                            disabled: value === defaultChoice.id,
                        })}
                        {renderCancelButton()}
                    </Box>
                </Wrapper>
            );

        case SAVING:
            return (
                <Wrapper>
                    {renderDropdown({ disabled: true })}
                    <Box>
                        {renderSaveButton({ loading: true })}
                        {renderCancelButton({ disabled: true })}
                    </Box>
                </Wrapper>
            );

        case ERROR:
            return (
                <Wrapper>
                    {renderDropdown({ error: true })}
                    <Box>
                        {renderSaveButton()}
                        {renderCancelButton()}
                    </Box>
                </Wrapper>
            );

        default:
            return (
                <Wrapper justifyContent={'flex-start'}>
                    <Chip variant={'outlined'} label={state.name} />
                    {isEditable && renderEditButton()}
                </Wrapper>
            );
    }
};

export default SchemaState;
