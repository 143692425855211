import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Paper, TablePagination, Typography } from '@mui/material';
import Loading from 'components/Loading/Loading';
import { DerivedFieldInterface } from 'queries/specifications/types';
import {
    HDPAccordion,
    HDPAccordionDetails,
    HDPAccordionGroup,
    HDPAccordionSummary,
} from 'src/components/HDPAccordion';
import { ACCORDION_IDS } from 'src/constants/constants';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import { FilterForm } from '../FilterForm';

interface LoadingFailedProps {
    onTryAgain: () => void;
}

const LoadingFailed = ({ onTryAgain }: LoadingFailedProps) => (
    <Paper>
        <Box
            p={4}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
        >
            <Typography variant={'body2'} paragraph>
                There was a problem loading the list of derived fields.
            </Typography>
            <Button variant={'outlined'} size={'small'} onClick={onTryAgain}>
                Try again
            </Button>
        </Box>
    </Paper>
);

interface DerivedFieldProps extends DerivedFieldInterface {
    i: number;
}

const DerivedField = ({ name, i, description, id }: DerivedFieldProps) => {
    const searchParam = ACCORDION_IDS.SPECIFICATION.DERIVED_FIELD + id;

    return (
        // @ts-expect-error TODO fix when accordion is replaced
        <HDPAccordion
            square
            size="xs"
            noBorder
            elevated
            searchParamId={searchParam}
        >
            <HDPAccordionSummary
                // @ts-expect-error TODO fix when accordion is replaced
                expandIcon={<ExpandMoreIcon placement="end" size="lg" />}
                data-test-id={formatTestId('open derived field', i.toString())}
                color="black"
                fontWeight="normal"
                header={
                    <Typography variant={'body1'} sx={{ marginLeft: 2 }}>
                        {name}
                    </Typography>
                }
            />
            {/*  @ts-expect-error TODO fix when accordion is replaced */}
            <HDPAccordionDetails>
                <Typography variant={'body2'}>{description}</Typography>
            </HDPAccordionDetails>
        </HDPAccordion>
    );
};

interface DerivedFieldsProps {
    data: { derivedFields: DerivedFieldInterface[]; pagingMetadata: any };
    handleChangeRowsPerPage: any;
    handleChangePage: any;
    handleFilter: any;
    handleTryAgain: any;
    derivedFieldParams: any;
    status: 'pending' | 'error' | 'success';
}

export const DerivedFields = ({
    data,
    handleChangePage,
    handleChangeRowsPerPage,
    handleFilter,
    handleTryAgain,
    derivedFieldParams,
    status,
}: DerivedFieldsProps) => {
    const derivedFields = data?.derivedFields;
    const total = data?.pagingMetadata?.total;
    switch (status) {
        case 'pending':
            return <Loading />;

        case 'error':
            return <LoadingFailed onTryAgain={handleTryAgain} />;

        case 'success':
            return (
                <section>
                    <Paper square>
                        <Box p={3}>
                            <FilterForm
                                initialFilterTerm={derivedFieldParams.filter}
                                onFilter={handleFilter}
                            />
                        </Box>
                    </Paper>
                    {/* @ts-expect-error TODO fix when accordion is replaced */}
                    <HDPAccordionGroup spread={false}>
                        {derivedFields.map((derivedField, i) => (
                            <DerivedField
                                key={`derived-field-${derivedField.id}`}
                                i={i + 1}
                                {...derivedField}
                            />
                        ))}
                    </HDPAccordionGroup>
                    {/* @ts-expect-error TODO fix when accordion is replaced */}
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={total}
                        rowsPerPage={derivedFieldParams.rowsPerPage}
                        page={derivedFieldParams.page}
                        backIconButtonProps={{
                            'aria-label': 'previous page',
                            'data-test-id': 'previousPage',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'next page',
                            'data-test-id': 'nextPage',
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </section>
            );

        default:
            return null;
    }
};

export default DerivedFields;
