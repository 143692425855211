import { useQuery } from '@tanstack/react-query';

import { getUseDerivedFieldsQueryOptions } from './options';
import { DerivedFieldsQueryOptions } from './types';

export default function useValidations(
    queryOptions: DerivedFieldsQueryOptions,
) {
    const options = getUseDerivedFieldsQueryOptions(queryOptions);

    return useQuery({
        ...options,

        select: data => data,
    });
}
