import { Link } from 'react-router-dom';
import {
    Box,
    Button,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { usePageTitle } from 'components';
import { SpecificationInterface } from 'queries/specifications/types';
import useSpecifications from 'queries/specifications/useSpecifications';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import { SpecState } from './SpecState';

import styles from './specs.module.scss';

const Loading = () => (
    <Paper>
        <Box p={4} display={'flex'} justifyContent={'center'}>
            <CircularProgress
                size={24}
                aria-label="Circular loading animation"
            />
        </Box>
    </Paper>
);

interface SpecTableRowProps {
    spec: SpecificationInterface;
}

const SpecTableRow = ({ spec }: SpecTableRowProps) => {
    if (!spec) return null;

    return (
        <TableRow hover className={styles.row}>
            <TableCell width={'25%'}>
                <Link
                    className={styles.link}
                    to={`/management/specifications/specs/${spec?.id}`}
                    data-test-id={formatTestId(
                        'linkTo',
                        `specification ${spec?.version}`,
                    )}
                >
                    Specification {spec?.version}
                </Link>
            </TableCell>
            <TableCell width={'25%'}>
                <SpecState specification={spec} />
            </TableCell>
            <TableCell width={'25%'}>Schema {spec?.schema?.version}</TableCell>
            <TableCell width={'25%'}>
                Ruleset {spec?.ruleset?.version}
            </TableCell>
        </TableRow>
    );
};

interface SpecTableProps {
    specs: SpecificationInterface[];
}

const SpecTable = ({ specs }: SpecTableProps) => {
    return (
        specs?.length && (
            <Paper square>
                <Table data-test-id={formatTestId('table', 'specifications')}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Specification</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Schema</TableCell>
                            <TableCell>Ruleset</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {specs.map(spec => (
                            <SpecTableRow key={spec.id} spec={spec} />
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        )
    );
};

const Specs = () => {
    usePageTitle('Specifications');

    const { data: specs, status } = useSpecifications({
        state: [],
    });

    const handleTryAgain = () => {
        // Refresh the page so that the data is fetched again
        window.location.reload();
    };

    switch (status) {
        case 'pending':
            return <Loading />;

        case 'success': {
            // sort specs by ID, so if a new one is added it will be at the top
            const sortedSpecs = specs.sort(
                (a: SpecificationInterface, b: SpecificationInterface) =>
                    b.id - a.id,
            );

            return <SpecTable specs={sortedSpecs} />;
        }

        case 'error':
            return (
                <Paper>
                    <Box
                        p={4}
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                    >
                        <Typography variant={'body2'} paragraph>
                            There was a problem loading the list of
                            specifications.
                        </Typography>
                        <Button
                            variant={'outlined'}
                            size={'small'}
                            onClick={handleTryAgain}
                        >
                            Try again
                        </Button>
                    </Box>
                </Paper>
            );

        default:
            return <Loading />;
    }
};

export default Specs;
