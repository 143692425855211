import { Schema } from 'queries/schemas/types';

import { get as fetchGet, put } from '../utils';

export const getAll = ({
    state,
}: {
    state: string[];
}): Promise<{ schemas: Schema[] }> =>
    fetchGet('/specifications/schemas', {
        query: { state },
    });

export const updateState = ({ id, stateId }: { id: number; stateId: string }) =>
    put(`/specifications/schemas/${id}/state`, {
        body: { stateId: parseInt(stateId, 10) },
    });
