import { useQuery } from '@tanstack/react-query';

import { getUseSpecificationsQueryOptions } from './options';
import { SpecificationsQueryOptions } from './types';

export default function useSpecifications(
    queryOptions: SpecificationsQueryOptions,
) {
    const options = getUseSpecificationsQueryOptions(queryOptions);

    return useQuery({
        ...options,
        select: data => data.specifications,
    });
}
