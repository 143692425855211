import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { usePageTitle } from 'components';
import { DateTime } from 'luxon';
import useRulesets from 'queries/rulesets/useRulesets';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import styles from './rulesets.module.scss';

const Loading = () => (
    <Paper>
        <Box p={4} display={'flex'} justifyContent={'center'}>
            <CircularProgress
                size={24}
                aria-label="Circular loading animation"
            />
        </Box>
    </Paper>
);

const formatDate = (date: string) =>
    DateTime.fromISO(date).toFormat('dd/LL/yyyy HH:mm');

interface RulesetTableRowProps {
    version: string;
    state: { name: string; id: number };
    updatedUtc: string;
    updatedBy: string;
}

const RulesetTableRow = ({
    version,
    state,
    updatedUtc,
    updatedBy,
}: RulesetTableRowProps) => {
    return (
        <TableRow hover className={styles.row}>
            <TableCell width={'25%'}>Ruleset {version}</TableCell>
            <TableCell width={'25%'}>
                <Chip variant={'outlined'} label={state.name} />
            </TableCell>
            <TableCell width={'25%'}>{formatDate(updatedUtc)}</TableCell>
            <TableCell width={'25%'}>{updatedBy}</TableCell>
        </TableRow>
    );
};

interface RulesetTableProps {
    rulesets: any[]; // TODO better type
}

const RulesetTable = ({ rulesets }: RulesetTableProps) => {
    return (
        <Paper square>
            <Table data-test-id={formatTestId('table', 'rulesets')}>
                <TableHead>
                    <TableRow>
                        <TableCell>Ruleset</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Last updated</TableCell>
                        <TableCell>Last updated by</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rulesets.map(ruleset => (
                        <RulesetTableRow key={ruleset.id} {...ruleset} />
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
};

const handleTryAgain = () => {
    window.location.reload();
};

const Rulesets = () => {
    const { data, status } = useRulesets({ state: [] });
    usePageTitle('Ruleset Versions');

    switch (status) {
        case 'pending':
            return <Loading />;

        case 'success':
            return <RulesetTable rulesets={data} />;

        case 'error':
            return (
                <Paper>
                    <Box
                        p={4}
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                    >
                        <Typography variant={'body2'} paragraph>
                            There was a problem loading the list of rulesets.
                        </Typography>
                        <Button
                            variant={'outlined'}
                            size={'small'}
                            onClick={handleTryAgain}
                        >
                            Try again
                        </Button>
                    </Box>
                </Paper>
            );

        default:
            return null;
    }
};

export default Rulesets;
