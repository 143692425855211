import { createContext } from 'react';
interface SpecTabPanelContextType {
    validationParams: {
        filter: string;
        page: number;
        rowsPerPage: number;
    };
    setValidationPage: (page: number) => void;
    setValidationRowsPerPage: (rowsPerPage: number) => void;
    setValidationFilterTerm: (filterTerm: string) => void;

    derivedFieldParams: {
        filter: string;
        page: number;
        rowsPerPage: number;
    };
    setDerivedFieldPage: (page: number) => void;
    setDerivedFieldRowsPerPage: (rowsPerPage: number) => void;
    setDerivedFieldsFilterTerm: (filterTerm: string) => void;
}

const SpecTabPanelContext = createContext<SpecTabPanelContextType>({
    validationParams: {
        filter: '',
        page: 0,
        rowsPerPage: 10,
    },
    setValidationPage: () => {},
    setValidationRowsPerPage: () => {},
    setValidationFilterTerm: () => {},

    derivedFieldParams: {
        filter: '',
        page: 0,
        rowsPerPage: 10,
    },
    setDerivedFieldPage: () => {},
    setDerivedFieldRowsPerPage: () => {},
    setDerivedFieldsFilterTerm: () => {},
});

export default SpecTabPanelContext;
