import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Chip, Grid, Tab, Tabs, Typography } from '@mui/material';
import {
    HDPAccordion,
    HDPAccordionDetails,
    HDPAccordionSummary,
} from 'components/HDPAccordion';
import TabPanel from 'components/Tabs/TabPanel';
import { ACCORDION_IDS } from 'constants/constants';
import { DateTime } from 'luxon';
import {
    SpecificationInterface,
    ValidationInterface,
} from 'queries/specifications/types';
import { formatTestId } from 'utils/formatTestId/formatTestId';

import { RegulatorConfigTable } from '../RegulatorConfigTable';
import RegulatorLabels from '../RegulatorLabels/RegulatorLabels';
import { RuleMetadata } from '../RuleMetadata';

import styles from './validation.module.scss';

const a11yprops = (index: number): { id: string; 'aria-controls': string } => {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
};

interface ValidationProps {
    validation: ValidationInterface;
    spec: SpecificationInterface;
}

const Validation = ({ validation, spec }: ValidationProps) => {
    const { id, code, updatedUtc } = validation;
    const formatDate = (date: string): string =>
        DateTime.fromISO(date).toFormat('dd/LL/yyyy');
    const searchParam = ACCORDION_IDS.SPECIFICATION.VALIDATION + id;

    const [tabValue, setTabValue] = useState('one');
    const handleTabChange = (
        _event: React.SyntheticEvent,
        newTabValue: string,
    ) => {
        setTabValue(newTabValue);
    };

    return (
        // @ts-expect-error TODO: fix when accordion is replaced
        <HDPAccordion
            square
            size="sm"
            noBorder
            elevated
            searchParamId={searchParam}
        >
            <HDPAccordionSummary
                // @ts-expect-error TODO: fix when accordion is replaced
                expandIcon={<ExpandMoreIcon placement="end" size="lg" />}
                color="black"
                data-test-id={formatTestId('open validation', id.toString())}
                fontWeight="normal"
                header={
                    <Grid container sx={{ marginLeft: 2 }}>
                        <Grid item xs={12}>
                            {updatedUtc && (
                                <Chip
                                    className={styles.validationsChip}
                                    size="small"
                                    label={`Last updated: ${formatDate(
                                        updatedUtc,
                                    )}`}
                                />
                            )}
                            <Typography variant={'body1'}>{code}</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <RegulatorLabels
                                regulators={validation.regulators}
                            />
                        </Grid>
                    </Grid>
                }
            />
            {/* @ts-expect-error TODO: fix when accordion is replaced */}
            <HDPAccordionDetails>
                <Grid container>
                    <Grid item xs={12}>
                        <>
                            <Tabs
                                variant="fullWidth"
                                value={tabValue}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                            >
                                <Tab
                                    value="one"
                                    label="Rule Tolerances"
                                    data-test-id={formatTestId(
                                        'tab',
                                        'rule tolerances',
                                    )}
                                    wrapped
                                    {...a11yprops(2)}
                                />
                                <Tab
                                    value="two"
                                    label="Rule Metadata"
                                    data-test-id={formatTestId(
                                        'tab',
                                        'rule metadata',
                                    )}
                                    wrapped
                                    {...a11yprops(1)}
                                />
                            </Tabs>

                            <TabPanel value={tabValue} index="one">
                                <RegulatorConfigTable
                                    validation={validation}
                                    spec={spec}
                                />
                            </TabPanel>

                            <TabPanel value={tabValue} index="two">
                                <RuleMetadata validation={validation} />
                            </TabPanel>
                        </>{' '}
                    </Grid>
                </Grid>
            </HDPAccordionDetails>
        </HDPAccordion>
    );
};

export default Validation;
