import { queryOptions } from '@tanstack/react-query';
import { getAllCollections } from 'src/services/api/collections/collections';

import { CollectionsQueryOptions } from './types';

export function getUseCollectionsQueryOptions({
    limit,
    offset,
}: CollectionsQueryOptions) {
    return queryOptions({
        queryKey: ['collections', limit, offset],
        queryFn: () => getAllCollections({ limit, offset }),
    });
}

export function getUseSingleCollectionQueryOptions() {
    return queryOptions({
        queryKey: ['collections'],
        queryFn: () => getAllCollections(),
    });
}
