import { queryOptions } from '@tanstack/react-query';
import { schemas as schemasApi } from 'services/api';

import { GetSchemasQueryOptions } from './types';

export function getUseSchemasQueryOptions({ state }: GetSchemasQueryOptions) {
    return queryOptions({
        queryKey: ['schemas', `${state.join(',')}`],
        queryFn: () => schemasApi.getAll({ state }),
    });
}
