import { useMutation, useQueryClient } from '@tanstack/react-query';
import { specifications as specificationsApi } from 'services/api';

import { DeleteSpecificationArgs } from './types';

export default function useDeleteSpecification() {
    const queryClient = useQueryClient();

    const { mutate, ...mutation } = useMutation({
        mutationFn: (args: DeleteSpecificationArgs) =>
            specificationsApi.deleteRequest(args),
        onSuccess: (
            mutationResponse,
            _error,
            variables: DeleteSpecificationArgs,
        ) => {
            queryClient.invalidateQueries({
                queryKey: ['specifications', ''],
            });
            queryClient.invalidateQueries({
                queryKey: ['specification', `${variables.id}`],
            });
        },
    });

    return { mutate, ...mutation };
}
